import React, { useEffect, useState } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import Axios from "axios";
import TabelaAdesaoMedicamentos from "../../components/TabelaAdesaoMedicamentos/TabelaAdesaoMedicamentos";

const Principal = (props) => {
  const [teleAssitenciasHoje, setTeleAssitenciasHoje] = useState("0");
  const [teleConsultoriasAbertas, setTeleConsultoriasAbertas] = useState("0");

  useEffect(() => {
    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/qtdTelecAbertas"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleConsultoriasAbertas(response.data[0].quantidade);
    });

    Axios.get("https://atendimento.mydigicare.com.br:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        if (response.data.user[0].tipo_usuario == "1") {
          carregaAgendamentosFarmaceuticos(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "5") {
          carregaAgendamentosNutricao(response.data.user[0].cpf);
        } else {
          carregaAgendamentosPsicologia(response.data.user[0].cpf);
        }
      }
    });
  }, []);

  const carregaAgendamentosFarmaceuticos = (cpf_origem) => {
    console.log(cpf_origem);
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/qtdTeleAssFarmaceutico",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data);
      setTeleAssitenciasHoje(response.data[0].quantidade);
    });
  };

  const carregaAgendamentosNutricao = (cpf_origem) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/qtdTeleAssNutricao",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleAssitenciasHoje(response.data[0].quantidade);
    });
  };

  const carregaAgendamentosPsicologia = (cpf_origem) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/qtdTeleAssPsicologia",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleAssitenciasHoje(response.data[0].quantidade);
    });
  };

  return (
    <>
      <SidebarFarmaceutico>
        <div class="main__container">
          <div class="main__title">
            <div class="main__greeting">
              <h1>Bem vindo ao dashboard!</h1>
              <p>Setor de atendimento</p>
            </div>
          </div>

          <div class="main__cards">
            <div class="card">
              <div class="card_inner">
                <p class="text-primary-p">Teleconsultorias Abertas</p>
                <span class="font-bold text-title">
                  {teleConsultoriasAbertas}
                </span>
                <i
                class="fa fa-user-o fa-2x text-lightblue"
                aria-hidden="true"
              ></i>
              </div>
            </div>

            <div class="card">
              <div class="card_inner">
                <p class="text-primary-p">Teleassistências Hoje</p>
                <span class="font-bold text-title">{teleAssitenciasHoje}</span>
                <i class="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
              </div>
            </div>  
          </div>

          <div className="charts">
              <div class="charts__left">
              <div class="charts__left__title">
                <div>
                  <TabelaAdesaoMedicamentos/>
                </div>
              </div>
              <div id="apex1"></div>
            </div>
          </div>

        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default Principal;
