import Axios from "axios";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import { formatDate } from "../../utils/date";
/*
1 - Farmacêutico
5 - Nutri
6 - Psicologia
7 - EducadorFisico
*/

const BuscaTeleAssFarm = (props) => {
  let history = useHistory();
  const [lista, setLista] = useState([]);
  const [listaTeleAssistencia, setListaTeleAssistencia] = useState([]);
  const [origem, setOrigem] = useState("");
  const [status, setStatus] = useState("");
  const [cpf, setCPF] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [filial, setFilial] = useState("");
  const [itemDigitado, setItemDigitado] = useState("");

  const resultadoDaBusca = lista.filter(
    (listVal) =>
      listVal.nome != null &&
      (listVal.nome.toUpperCase().includes(itemDigitado.toUpperCase()) ||
        listVal.id.toString().includes(itemDigitado))
  );

  Axios.defaults.withCredentials = true;

  function formatHora(date) {
    var d = new Date(date),
      hora = "" + d.getHours(),
      year = d.getFullYear(),
      minuto = "" + d.getMinutes();
    if (year < "2021") {
      return "";
    } else {
      return [hora, minuto].join(":");
    }
  }

  useEffect(() => {
    Axios.get("https://atendimento.mydigicare.com.br:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setTipoUsuario(response.data.user[0].tipo_usuario);
        if (response.data.user[0].tipo_usuario == "1") {
          carregaAgendamentosFarmaceuticos(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "5") {
          carregaAgendamentosNutricao(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "6") {
          carregaAgendamentosPsicologia(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "7") {
          carregaAgendamentosEducadorFisico(response.data.user[0].cpf);
        }
      }
    });
  }, []);

  const carregaAgendamentosFarmaceuticos = (cpf_origem) => {
    setCPF(cpf_origem);
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/carregaTeleAssFarmaceutico",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
      setListaTeleAssistencia(response.data);
    });
  };

  const carregaAgendamentosEducadorFisico = (cpf_origem) => {
    setCPF(cpf_origem);
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/carregaTeleAssEducadorFisico",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
      setListaTeleAssistencia(response.data);
    });
  };

  const carregaAgendamentosNutricao = (cpf_origem) => {
    setCPF(cpf_origem);
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/carregaTeleAssNutricao",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
      setListaTeleAssistencia(response.data);
    });
  };

  const carregaAgendamentosPsicologia = (cpf_origem) => {
    setCPF(cpf_origem);
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/carregaTeleAssPsicologia",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
      setListaTeleAssistencia(response.data);
    });
  };

  const filtroOrigem = () => {
    let temp = [...listaTeleAssistencia];
    if (filial != "") {
      const results = temp.filter((item) => {
        return item.filial == filial;
      });
      temp = [...results];
    }
    if (origem != "") {
      const results = temp.filter((item) => {
        return item.origem === origem;
      });
      temp = [...results];
    }
    if (status != "") {
      const results = temp.filter((item) => {
        return item.status === status;
      });
      temp = [...results];
    }
    setLista(temp);
  };

  const consultaProntuario = (id) => {
    history.push({
      pathname: "/farmaceutico/form",
      state: { detail: id },
    });
  };

  const alterarFone = (id) => {
    history.push({
      pathname: "/farmaceutico/alterarFone",
      state: { detail: id },
    });
  };

  return (
    <>
      <SidebarFarmaceutico>
        <div class="main__container">
          <div>
            <div class="titulo">
              <h3>BUSCAR TELEASSISTÊNCIA</h3>
            </div>
            <div class="div_teste">
              <div class="div2">
                <div class="item">
                  <p>Teleassistência: </p>
                  <InputMask
                    class="nome"
                    type="text"
                    value={itemDigitado}
                    onChange={(e) => setItemDigitado(e.target.value)}
                    onKeyUp={(e) => setItemDigitado(e.target.value)}
                    placeholder="Digite o NOME do Cliente ou o PROTOCOLO da assistência"
                  ></InputMask>
                </div>
              </div>
              <div class="subtitulo2">
                <h3>Filtros </h3>
              </div>
              <div class="div2">
                <div className="item">
                  <p>Filial: </p>
                  <select
                    value={filial}
                    onChange={(e) => {
                      setFilial(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="1">RS</option>
                    <option value="2">BA</option>
                  </select>
                </div>
                <div className="item">
                  <p>Origem: </p>
                  <select
                    value={origem}
                    onChange={(e) => {
                      setOrigem(e.target.value);
                    }}
                  >
                    <option></option>
                    <option>PROTRAC</option>
                    <option>Privado</option>
                    <option>SUS</option>
                    <option>Plano de Saúde</option>
                    <option>Eldorado</option>
                    <option>Demedia</option>
                    <option>HPS</option>
                    <option>Outro</option>
                  </select>
                </div>
                <div className="item">
                  <p>Status: </p>
                  <select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option></option>
                    <option>Aberta</option>
                    <option>Encerrada</option>
                    <option>Acompanhamento</option>
                    <option>Desmarcado</option>
                  </select>
                </div>
                <div class="item">
                  <p> </p>
                  <button onClick={filtroOrigem}>SELECIONAR</button>
                </div>
              </div>
              <table class="content-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NOME PACIENTE</th>
                    <th>FILIAL</th>
                    <th>ORIGEM</th>
                    <th>STATUS</th>
                    <th>LINK ATENDIMENTO</th>
                    <th>PRONTUÁRIO</th>
                    <th>FONE</th>
                    <th>TIPO</th>
                    <th>DATA</th>
                    <th>HORA INÍCIO</th>
                    <th>HORA FIM</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {resultadoDaBusca.map((item) => (
                    <tr>
                      <th>{item.id}</th>
                      <th>{item.nome}</th>
                      {item.filial == 1 && <th>RS</th>}
                      {item.filial == 2 && <th>BA</th>}
                      <th>{item.origem}</th>
                      <th>{item.status}</th>
                      <th>
                        <a href={item.link} target="_blank">
                          Clique aqui
                        </a>
                      </th>
                      <th>
                        <a
                          style={{ cursor: "pointer" }}
                          id={item.prontuario_id}
                          onClick={(e) => consultaProntuario(e.target.id)}
                        >
                          Visualizar prontuário
                        </a>
                      </th>
                      <th>
                        <a
                          style={{ cursor: "pointer" }}
                          id={item.prontuario_id}
                          onClick={(e) => alterarFone(e.target.id)}
                        >
                          Alterar telefone
                        </a>
                      </th>
                      <th>{item.tipo}</th>
                      <th>{formatDate(item.data_inicio)}</th>
                      <th>{formatHora(item.data_inicio)}</th>
                      <th>{formatHora(item.data_fim)}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default BuscaTeleAssFarm;
