import Axios from "axios";
import React, { useEffect, useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { useHistory, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";

const AlterarFone = (props) => {
  let history = useHistory();
  Axios.defaults.withCredentials = true;

  const [nome, setNome] = useState("");
  const [foneAntigo, setFoneAntigo] = useState("");
  const [foneNovo, setFoneNovo] = useState("");
  const location = useLocation();
  const [id, setId] = useState(location.state.detail);

  useEffect(() => {
    Axios.post("https://atendimento.mydigicare.com.br:3002/carregaPront", {
      id: id,
    }).then((response) => {
      if (response.data.message == 0) {
        alert("Erro ao carregar dados");
      } else {
        setNome(response.data[0].nome);
        setFoneAntigo(response.data[0].fone);
      }
    });
  }, []);

  const alteraFone = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mydigicare.com.br:3002/alteraFone", {
      id: id,
      fone: foneNovo,
    }).then((response) => {
      if (response.data.message == "1") {
        alert("Telefone alterado com sucesso");
        history.push("/adm/buscaTeleA");
      } else {
        if (response.data.message == "0") {
          alert("Erro ao alterar telefone");
        }
      }
    });
  };

  return (
    <>
      <SidebarAdministrativo>
        <div className="main__container">
          <div className="form">
            <div className="titulo">
              <h3>ALTERAR FONE</h3>
            </div>
            <div className="div_teste">
              <div className="item">
                <p>Paciente: </p>
                <input readonly="readonly" type="text" value={nome}></input>
              </div>
              <div className="item">
                <p>Fone antigo: </p>
                <InputMask
                  readonly="readonly"
                  mask="(99) 99999-9999"
                  value={foneAntigo}
                ></InputMask>
              </div>
              <div className="item">
                <p>Novo fone: </p>
                <InputMask
                  mask="(99) 99999-9999"
                  value={foneNovo}
                  onChange={(e) => {
                    setFoneNovo(e.target.value);
                  }}
                ></InputMask>
              </div>
              <div className="item">
                <p></p>
                <button onClick={alteraFone}>Confirmar</button>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default AlterarFone;
