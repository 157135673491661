import React, { useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import io from "socket.io-client";
import { useLocation } from "react-router";
import "../Farmaceutico/ChatTeleconsultoria.css";
import Chat from "../../utils/ChatTeleconsultoria";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";

const ChatTeleconsultoriaAdmin = () => {
  const socket = io.connect("https://atendimento.mydigicare.com.br:3001");
  const location = useLocation();
  const room = useState(location.state.room);
  const username = useState(location.state.nome);

  return (
    <div>
      <SidebarAdministrativo>
        <Row gutter={40} align="pace-around">
          <Col span={6}>
            <JitsiMeeting
              domain={"jitsi.mydigicare.com.br"}
              roomName={room}
              getIFrameRef={(node) => {
                node.style.width = "100%";
                node.style.height = "100%";
              }}
            />
          </Col>
          <Col span={6}>
            <Chat socket={socket} username={username} room={room} />
          </Col>
        </Row>
      </SidebarAdministrativo>
    </div>
  );
};

export default ChatTeleconsultoriaAdmin;
