import React, { useEffect } from "react";
import {Prompt} from "react-router-dom";

const useUnasavedChangesWarning = (message = "Você tem alterações não salvas. Tem certeza que deseja sair?") => {
    const [showWarning, setShowWarning] = React.useState(false);

    useEffect(() => {
        // Detectar browser close
        window.onbeforeunload = showWarning && (() => message);

        return () => {
            window.onbeforeunload = null;
        }
    }, [showWarning]);

    const routerPrompt = <Prompt when={showWarning} message={message} />

    return [routerPrompt, () => setShowWarning(true), () => setShowWarning(false)];
}

export default useUnasavedChangesWarning;