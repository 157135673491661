import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import depoimento1 from "../../imagens/depoimentos/luana.jpeg";
import depoimento2 from "../../imagens/depoimentos/carminda.jpeg";
import depoimento3 from "../../imagens/depoimentos/alipio.jpeg";
import depoimento4 from "../../imagens/depoimentos/silvio.jpeg";
import depoimento5 from "../../imagens/depoimentos/beatriz.jpeg";
import depoimento6 from "../../imagens/depoimentos/derek.jpeg";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img src={depoimento1} />
          <div className="myCarousel">
            <h3>Luana Brum Azevedo</h3>
            <h4>Cabeleireira e manicure</h4>
            <p>
              Muito atenciosos! Muito dedicados! Humanos! Gostei muito dessa
              experiência! Me ajudou bastante e seguirei firme as instruções da
              Laura! Levarei para a vida!
            </p>
          </div>
        </div>

        <div>
          <img src={depoimento2} />
          <div className="myCarousel">
            <h3>Carminda de Mattos</h3>
            <h4>Aposentada</h4>
            <p>
              Foi em uma fase muito ruim da minha vida, agradeço muito pela
              atenção, a farmacêutica Laura, Dr. Marlise pela atenção e pelos
              cuidados, amo elas de coração, competência a altura das
              necessidades de uma pessoa enferma e fica toda minha gratidão.
            </p>
          </div>
        </div>

        <div>
          <img src={depoimento3} />
          <div className="myCarousel">
            <h3>Alípio e Rosângela</h3>
            <h4>aposentado/cabeleireira</h4>
            <p>
              Agradecemos pela atenção e cuidados. E pela orientação na
              alimentação. Vocês são como anjos, que Deus enviou para ajudar no
              momento de desespero. Vocês são pessoas maravilhosas! Vocês são o
              Grupo do Bem.
            </p>
          </div>
        </div>

        <div>
          <img src={depoimento4} />
          <div className="myCarousel">
            <h3>Silvio e Odete</h3>
            <h4>Aposentados</h4>
            <p>
              Vocês cuidam da nossa saúde. Orientam quanto aos medicamentos,
              cuidado e monitoramento da pressão arterial, glicose, saturação e
              temperatura corporal. Vocês são anjos!
            </p>
          </div>
        </div>

        <div>
          <img src={depoimento5} />
          <div className="myCarousel">
            <h3>Beatriz Higashi</h3>
            <h4>Enfermeira</h4>
            <p>
              Junto a MyDIGICARE, desenvolvemos estratégias de acordo com as
              necessidades de cada enfermo nos dando suporte em relação às
              questões farmacêuticas. Um suporte essencial para o uso correto
              dos fármacos, evitando assim o uso incorreto e a readmissão
              hospitalar. Equipe proativa, sempre a disposição de toda a equipe.
            </p>
          </div>
        </div>

        <div>
          <img src={depoimento6} />
          <div className="myCarousel">
            <h3>Derek Luan</h3>
            <h4>Médico Acadêmico Pesquisador</h4>
            <p>
              Sou Médico Acadêmico Pesquisador, tenho doença neurológica crônica e genética
              e realizo a Teleconsulta Farmacêutica semanal para tratamento a
              alguns meses com a Dra Marlise da MyDIGICARE. Desde que comecei o
              acompanhamento, tenho notado uma diferença e uma melhora no meu
              quadro clínico (saúde). A MyDIGICARE conta com uma equipe com
              farmacêuticos/as, nutricionistas e demais profissionais da Saúde.
              Tratamento e acompanhamento excelentes.
            </p>
          </div>
        </div>
      </Carousel>
    );
  }
}
