export function verifyDate(dataInicio, dataFim) {

    var inicio = new Date(dataInicio)
    var fim = new Date(dataFim)
    if(inicio.getTime() < fim.getTime()){
        return true
    }
    else {
        return false
    }
}