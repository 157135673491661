import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { getCurrentDate, getCurrentFullDate } from "../../utils/date";
const DesmarcarTeleA = (props) => {
  const [teleaID, setTeleaID] = useState();
  const [listaTeleAss, setListaTeleAss] = useState([]);
  const [motivo, setMotivo] = useState();
  const [dataInicio, setDataInicio] = useState("");
  const [dataAux, setDataAux] = useState("");
  const [dataFim, setDataFim] = useState("");
  Axios.defaults.withCredentials = true;
  let history = useHistory();

  const alterar = () => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/alteraHorarioAtendimento",
      {
        id: teleaID,
        data: dataInicio,
      }
    ).then((response) => {
      if (response.data.message == "1") {
        Axios.post(
          "https://atendimento.mydigicare.com.br:3002/alteraHorarioTeleAss",
          {
            id: teleaID,
            data_inicio: dataInicio,
            data_fim: dataFim,
          }
        ).then((response) => {
          if (response.data.message == "1") {
            alert("Horário alterado com sucesso!");
            history.push("/adm");
          } else {
            alert("Erro ao alterar horário!");
          }
        });
      } else {
        alert("Erro ao alterar horário!");
      }
    });
  };

  const pesquisaDados = () => {
    if (teleaID != "") {
      Axios.post(
        "https://atendimento.mydigicare.com.br:3002/retornaHorarioTeleAssistenciaByID",
        {
          id: teleaID,
        }
      ).then((response) => {
        console.log(response.data);
        if (
          response.data[0].data_inicio == "" ||
          response.data[0].data_fim == ""
        ) {
          alert("Teleassistência sem horário encontrado!");
        } else {
          setDataAux(response.data[0].data_inicio);
          let today = new Date(response.data[0].data_inicio);
          let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
          let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
          setDataInicio(date + "T" + time);
          today = new Date(response.data[0].data_fim);
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
          time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
          setDataFim(date + "T" + time);
        }
      });
    }
  };

  useEffect(() => {
    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/retornaIDNomeTeleAssistencia"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  }, []);

  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <div>
            <div class="titulo">
              <h3>ALTERAR HORÁRIO - TELEASSISTÊNCIA</h3>
            </div>
            <div class="div_teste">
              <div className="div2">
                <div className="item">
                  <p>ID da Assistência: </p>
                  <input
                    value={teleaID}
                    type="text"
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="item">
                  <p>Selecionar Teleassistência: </p>
                  <select
                    value={teleaID}
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  >
                    <option value="0">-</option>
                    {listaTeleAss.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="div2">
                <div class="item">
                  <button onClick={pesquisaDados}>PESQUISAR</button>
                </div>
              </div>
              <div className="div2">
                <div class="item">
                  <p>Data Inicio: </p>
                  <input
                    value={dataInicio}
                    type="datetime-local"
                    min={getCurrentFullDate()}
                    onChange={(e) => {
                      setDataInicio(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="item">
                  <p>Data Fim: </p>
                  <input
                    value={dataFim}
                    type="datetime-local"
                    min={getCurrentFullDate()}
                    onChange={(e) => {
                      setDataFim(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p></p>
                  <button onClick={alterar}>ALTERAR</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};
export default DesmarcarTeleA;
