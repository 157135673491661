import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import { getCurrentDate } from "../../utils/date";
const DesmarcarTeleA = (props) => {
  const [teleaID, setTeleaID] = useState();
  const [listaTeleAss, setListaTeleAss] = useState([]);
  const [motivo, setMotivo] = useState();
  Axios.defaults.withCredentials = true;
  let history = useHistory();
  const desmarcar = () => {
    if (teleaID == "" || motivo == "") {
      alert("Por favor, preencha todos os campos!");
    } else {
      const isFound = listaTeleAss.some((element) => {
        if (element.id == teleaID) {
          return true;
        }
        return false;
      });
      if (isFound) {
        Axios.defaults.withCredentials = false;
        Axios.get("https://atendimento.mydigicare.com.br:3002/loginCPF", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }).then((response) => {
          Axios.post(
            "https://atendimento.mydigicare.com.br:3002/desmarcaTeleAMotivo",
            {
              id: teleaID,
              responsavel: response.data,
              data: getCurrentDate("-"),
              motivo: motivo,
            }
          ).then((response) => {
            if (response.data.message == "1") {
              Axios.post(
                "https://atendimento.mydigicare.com.br:3002/deletaAtendimento",
                {
                  id: teleaID,
                }
              ).then((response) => {
                if (response.data.message == "1") {
                  Axios.post(
                    "https://atendimento.mydigicare.com.br:3002/desmarcaTeleA",
                    {
                      id: teleaID,
                    }
                  ).then((response) => {
                    if (response.data.message == "1") {
                      alert("Teleassistência desmarcada com sucesso!");
                      history.push("/adm");
                    } else {
                      alert("Erro ao desmarcar teleassistência!");
                    }
                  });
                } else {
                  alert("Erro ao desmarcar teleassistência!");
                }
              });
            } else {
              alert("Erro ao desmarcar teleassistência!");
            }
          });
        });
      } else {
        alert("Teleassistência não encontrada!");
      }
    }
  };
  useEffect(() => {
    Axios.get("https://atendimento.mydigicare.com.br:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        if (response.data.user[0].tipo_usuario == "1") {
          carregaAgendamentosFarmaceutico(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "5") {
          carregaAgendamentosNutricao(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "6") {
          carregaAgendamentosPsicologia(response.data.user[0].cpf);
        }
        if (response.data.user[0].tipo_usuario == "7") {
          carregaAgendamentosEducadorFisico(response.data.user[0].cpf);
        }
      }
    });
  }, []);

  const carregaAgendamentosEducadorFisico = (cpf) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/retornaIDNomeTeleAssistenciaEducadorFisico",
      { id: cpf }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };

  const carregaAgendamentosFarmaceutico = (cpf) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/retornaIDNomeTeleAssistenciaFarmaceutico",
      { id: cpf }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };

  const carregaAgendamentosNutricao = (cpf) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/retornaIDNomeTeleAssistenciaNutricao",
      {
        id: cpf,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };

  const carregaAgendamentosPsicologia = (cpf) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/retornaIDNomeTeleAssistenciaPsicologia",
      {
        id: cpf,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };
  return (
    <>
      <SidebarFarmaceutico>
        <div class="main__container">
          <div>
            <div class="titulo">
              <h3>DESMARCAR TELEASSISTÊNCIA</h3>
            </div>
            <div class="div_teste">
              <div className="div2">
                <div className="item">
                  <p>ID da Assistência: </p>
                  <input
                    value={teleaID}
                    type="text"
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="item">
                  <p>Selecionar Teleassistência: </p>
                  <select
                    value={teleaID}
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  >
                    <option value="0">-</option>
                    {listaTeleAss.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="div2">
                <div class="item">
                  <p>Observação / Motivo: </p>
                  <input
                    class="nome"
                    type="text"
                    value={motivo}
                    onChange={(e) => {
                      setMotivo(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p></p>
                  <button onClick={desmarcar}>DESMARCAR</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarFarmaceutico>
    </>
  );
};
export default DesmarcarTeleA;
