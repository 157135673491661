import React from 'react'

function ItemLista(props) {
    return (
        <div class="item_lista">
        <div class="conteudo_pequeno">
          <div class="subconteudo">
            <p><b>Solicitante: </b>{props.cpf}</p>
            <p><b>Farmaceutico: </b>{props.cpf_farma}</p>
            <p><b>{props.area}</b></p>
          </div>
          <button>VER SOLICITAÇÃO COMPLETA</button>
        </div>
        <div class="conteudo_grande">
          <textarea value={props.assunto} readonly style={{resize: "none"}}></textarea>
        </div>
      </div>
    )
}

export default ItemLista
