import Axios from "axios";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { formatDate } from "../../utils/date";

const BuscaTeleA = (props) => {
  let history = useHistory();
  const [lista, setLista] = useState([]);
  const [listaTeleAssistencia, setListaTeleAssistencia] = useState([]);
  const [origem, setOrigem] = useState("");
  const [filial, setFilial] = useState("");
  const [status, setStatus] = useState("");
  const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
  const [listaNutricao, setListaNutricao] = useState([]);
  const [listaPsicologia, setListaPsicologia] = useState([]);
  const [ListaEducadorFisico, setListaEducadorFisico] = useState([]);
  const [farmaceutico, setFarmaceutico] = useState("");
  const [nutricao, setNutricao] = useState("");
  const [psicologia, setPsicologia] = useState("");
  const [educadorFisico, setEducadorFisico] = useState("");
  const [area, setArea] = useState("");

  const [itemDigitado, setItemDigitado] = useState("");

  const resultadoDaBusca = lista.filter(
    (listVal) =>
      listVal.nome != null &&
      (listVal.nome.toUpperCase().includes(itemDigitado.toUpperCase()) ||
        listVal.id.toString().includes(itemDigitado))
  );

  Axios.defaults.withCredentials = false;

  function formatHora(date) {
    var d = new Date(date),
      hora = "" + d.getHours(),
      year = d.getFullYear(),
      minuto = "" + d.getMinutes();
    if (year < "2021") {
      return "";
    } else {
      return [hora, minuto].join(":");
    }
  }

  useEffect(() => {
    Axios.defaults.withCredentials = false;

    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/retornaTeleAssistencia"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
      setListaTeleAssistencia(response.data);
    });

    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/lista_farmaceuticos"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaFarmaceutico(response.data);
    });
    Axios.get("https://atendimento.mydigicare.com.br:3002/lista_nutricao").then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response.data[0]);
        setListaNutricao(response.data);
      }
    );
    /*
    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/lista_educador_fisico"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaEducadorFisico(response.data);
    });
    */

    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/lista_psicologia"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaPsicologia(response.data);
    });
  }, []);

  const filtros = () => {
    let temp = [...listaTeleAssistencia];
    if (filial != "") {
      const results = temp.filter((item) => {
        return item.filial == filial;
      });
      temp = [...results];
    }
    if (origem != "") {
      const results = temp.filter((item) => {
        return item.origem === origem;
      });
      temp = [...results];
    }
    if (area != "") {
      const results = temp.filter((item) => {
        switch (area) {
          case "0":
            return item.id_farmaceutico !== null;
          case "1":
            return item.id_nutricao !== null;
          case "2":
            return item.id_psicologo !== null;
        }
      });
      temp = [...results];
    }
    if (status != "") {
      const results = temp.filter((item) => {
        return item.status === status;
      });
      temp = [...results];
    }
    if (farmaceutico != "") {
      const results = temp.filter((item) => {
        return item.id_farmaceutico == farmaceutico;
      });
      temp = [...results];
    }
    if (nutricao != "") {
      const results = temp.filter((item) => {
        return item.id_nutricao == nutricao;
      });
      temp = [...results];
    }
    if (psicologia != "") {
      const results = temp.filter((item) => {
        return item.id_psicologo == psicologia;
      });
      temp = [...results];
    }
    /*
    if (educadorFisico != "") {
      console.log(educadorFisico);
      const results = temp.filter((item) => {
        console.log(item);
        return item.id_educador_fisico == educadorFisico;
      });
      temp = [...results];
    }
    */
    setLista(temp);
  };

  const consultaProntuario = (id) => {
    history.push({
      pathname: "/adm/form",
      state: { detail: id },
    });
  };

  const alterarFone = (id) => {
    history.push({
      pathname: "/adm/alterarFone",
      state: { detail: id },
    });
  };

  const mensagemWhatsapp = (nome, telefone, data, link, codigo) => {
    /*
        Olá, tudo bem? Estamos entrando em contato referente a consulta farmacêutica do paciente NOME que será realizada pela MyDIGICARE no DIA - HORA.
        Abaixo, seguem as informações para realizar a sua consulta.

        Link de acesso: LINK
        Código verificador: CODIGO

        Após a confirmação do código verificador, será necessário aceitar os termos de uso da plataforma.

        Desejamos uma ótima consulta! 
        Caso tenha alguma dificuldade em acessar, por favor nos avise.
        Atenciosamente, equipe MyDIGICARE.  

        */

    if (
      nome != null &&
      telefone != null &&
      data != null &&
      link != null &&
      codigo != null
    ) {
      //alert("Mensagem enviada com sucesso!");
      //window.open(`https://api.whatsapp.com/send?phone=55${telefone}&text=Olá,%20${nome}!%20Gostaria%20de%20consultar%20o%20prontuário%20de%20${data}%20no%20link%20${link}%20e%20o%20código%20de%20acesso%20é%20${codigo}.%20Obrigado!`);

      // https://meyerweb.com/eric/tools/dencoder/
      let url = link.replace("https://", "");
      let fone = telefone.replace("(", "");
      fone = fone.replace(")", "");
      fone = fone.replace("-", "");
      fone = fone.replace(" ", "");
      let result =
        "https://wa.me/55" +
        fone +
        "?text=Ol%C3%A1%2C%20tudo%20bem%3F%20Estamos%20entrando%20em%20contato%20referente%20a%20consulta%20farmac%C3%AAutica%20do%20paciente%20" +
        nome +
        "%20que%20ser%C3%A1%20realizada%20pela%20MyDigiCare%20no%20" +
        formatDate(data) +
        "%20-%20" +
        formatHora(data) +
        ".%0AAbaixo%2C%20seguem%20as%20informa%C3%A7%C3%B5es%20para%20realizar%20a%20sua%20consulta.%0A%0ALink%20de%20acesso%3A%20" +
        url +
        "%0A*C%C3%B3digo%20verificador%3A%20" +
        codigo +
        "*" +
        "%0A%0AAp%C3%B3s%20a%20confirma%C3%A7%C3%A3o%20do%20c%C3%B3digo%20verificador%2C%20ser%C3%A1%20necess%C3%A1rio%20aceitar%20os%20termos%20de%20uso%20da%20plataforma.%0A%0A*N%C3%83O%20ESQUE%C3%87A%20DE%20HABILITAR%20AS%20PERMISS%C3%95ES%20DE%20%C3%81UDIO%20E%20V%C3%8DDEO%20QUANDO%20SOLICITADOS%20NO%20APLICATIVO.*%0A%0ADesejamos%20uma%20%C3%B3tima%20consulta!%20%0ACaso%20tenha%20alguma%20dificuldade%20em%20acessar%2C%20por%20favor%20nos%20avise.%0A_%0AAtenciosamente%2C%20%0AEquipe%20MyDIGICARE.";
      return (
        <a href={result} target="_blank">
          Clique aqui
        </a>
      );
    } else {
      return <a></a>;
    }
  };

  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <div>
            <div class="titulo">
              <h3>BUSCAR TELEASSISTÊNCIA</h3>
            </div>
            <div class="div_teste">
              <div class="div2">
                <div class="item">
                  <p>Teleassistência: </p>
                  <InputMask
                    class="nome"
                    type="text"
                    value={itemDigitado}
                    onChange={(e) => setItemDigitado(e.target.value)}
                    onKeyUp={(e) => setItemDigitado(e.target.value)}
                    placeholder="Digite o NOME do Cliente e o PROTOCOLO da assistência"
                  ></InputMask>
                </div>
              </div>
              <div class="subtitulo2">
                <h3>Filtros </h3>
              </div>
              <div class="div2">
                <div className="item">
                  <p>Filial: </p>
                  <select
                    value={filial}
                    onChange={(e) => {
                      setFilial(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="1">RS</option>
                    <option value="2">BA</option>
                  </select>
                </div>
                <div className="item">
                  <p>Origem: </p>
                  <select
                    value={origem}
                    onChange={(e) => {
                      setOrigem(e.target.value);
                    }}
                  >
                    <option></option>
                    <option>PROTRAC</option>
                    <option>Privado</option>
                    <option>SUS</option>
                    <option>Plano de Saúde</option>
                    <option>Eldorado</option>
                    <option>Demedia</option>
                    <option>HPS</option>
                    <option>Outro</option>
                  </select>
                </div>
                <div className="item">
                  <p>Área: </p>
                  <select
                    value={area}
                    onChange={(e) => {
                      setArea(e.target.value);
                    }}
                  >
                    <option></option>
                    <option value="0">Farmácia</option>
                    <option value="1">Nutrição</option>
                    <option value="2">Psicologia</option>
                  </select>
                </div>
                <div className="item">
                  <p>Status: </p>
                  <select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option></option>
                    <option>Aberta</option>
                    <option>Encerrada</option>
                    <option>Acompanhamento</option>
                    <option>Desmarcado</option>
                  </select>
                </div>
                <div className="item">
                  <p>Farmacêutico: </p>
                  <select
                    value={farmaceutico}
                    onChange={(e) => {
                      setFarmaceutico(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    {listaFarmaceutico.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
                <div className="item">
                  <p>Nutrição: </p>
                  <select
                    value={nutricao}
                    onChange={(e) => {
                      setNutricao(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    {listaNutricao.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
                <div className="item">
                  <p>Psicologo: </p>
                  <select
                    value={psicologia}
                    onChange={(e) => {
                      setPsicologia(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    {listaPsicologia.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
                <div class="item">
                  <p></p>
                  <button onClick={filtros}>SELECIONAR</button>
                </div>
              </div>
              <table class="content-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NOME PACIENTE</th>
                    <th>FILIAL</th>
                    <th>ORIGEM</th>
                    <th>FARMACÊUTICO</th>
                    <th>NUTRICIONISTA</th>
                    <th>PSICOLOGO</th>
                    <th>EDUCADOR FÍSICO</th>
                    <th>STATUS</th>
                    <th>LINK</th>
                    <th>ENVIAR MENSAGEM</th>
                    <th>PRONTUÁRIO</th>
                    <th>FONE</th>
                    <th>TIPO</th>
                    <th>DATA</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {resultadoDaBusca.map((item) => (
                    <tr>
                      <th>{item.id}</th>
                      <th>{item.nome}</th>
                      {item.filial == 1 && <th>RS</th>}
                      {item.filial == 2 && <th>BA</th>}
                      <th>{item.origem}</th>
                      <th>{item.nome_farmaceutico}</th>
                      <th>{item.nome_nutricao}</th>
                      <th>{item.nome_psicologo}</th>
                      <th>{item.nome_educador_fisico}</th>
                      <th>{item.status}</th>
                      <th>
                        <a href={item.link} target="_blank">
                          Atendimento
                        </a>
                        <br></br>
                        <a href={item.link_paciente} target="_blank">
                          Paciente
                        </a>
                      </th>
                      <th>
                        {mensagemWhatsapp(
                          item.nome,
                          item.fone,
                          item.data_inicio,
                          item.link_paciente,
                          item.codigo_verificador
                        )}
                      </th>
                      <th>
                        <a
                          style={{ cursor: "pointer" }}
                          id={item.prontuario_id}
                          onClick={(e) => consultaProntuario(e.target.id)}
                        >
                          Visualizar prontuário
                        </a>
                      </th>
                      <th>
                        <a
                          style={{ cursor: "pointer" }}
                          id={item.prontuario_id}
                          onClick={(e) => alterarFone(e.target.id)}
                        >
                          Alterar telefone
                        </a>
                      </th>
                      <th>{item.tipo}</th>
                      <th>{formatDate(item.data_inicio)}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default BuscaTeleA;
