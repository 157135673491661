import React from 'react';
import './Loader.css';
const Loading = (props) => {
    return (
        <>
        <div className="loader">
            <h1>Aguarde...</h1>
            <div className="loading">
            </div>
        </div>
        </>        
    )
}

export default Loading