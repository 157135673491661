import React from 'react';
import SidebarSolicitante from '../../components/sidebar_solicitante/Sidebar_Solicitante'

const PrincipalSolicitante = (props) => {
    return (
        <>
           <SidebarSolicitante>
                <h3>Tela Principal</h3>
           </SidebarSolicitante>
        </>        
    )
}

export default PrincipalSolicitante