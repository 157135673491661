import React, { useEffect, useState } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Inject,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import Axios from "axios";

/*
1 - Farmacêutico
5 - Nutri
6 - Psicologia
7 - EducadorFisico
*/

const AgendaFarm = (props) => {
  Axios.defaults.withCredentials = true;

  const [data, setData] = useState("");
  useEffect(() => {
    Axios.get("https://atendimento.mydigicare.com.br:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        if (response.data.user[0].tipo_usuario == "1") {
          carregaAgendamentosFarmaceuticos(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "5") {
          carregaAgendamentosNutricao(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "6") {
          carregaAgendamentosPsicologia(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario == "7") {
          carregaAgendamentosEducadorFisico(response.data.user[0].cpf);
        }
      }
    });
  }, []);

  const carregaAgendamentosFarmaceuticos = (cpf) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/carregaAgendaFarmaceutico",
      { id: cpf }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      console.log(response.data);
      setData(response.data);
    });
  };

  const carregaAgendamentosNutricao = (cpf) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/carregaAgendaNutricao",
      { id: cpf }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      console.log(response.data);
      setData(response.data);
    });
  };

  const carregaAgendamentosPsicologia = (cpf) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/carregaAgendaPsicologia",
      { id: cpf }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      console.log(response.data);
      setData(response.data);
    });
  };
  const carregaAgendamentosEducadorFisico = (cpf) => {
    Axios.post("https://atendimento.mydigicare.com.br:3002/carregaAgendaEducadorFisico", {
      id: cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      console.log(response.data);
      setData(response.data);
    });
  };

  const fields = {
    id: "id",
    subject: { name: "nome", title: "Compromisso", default: "Não Informado" },
    location: { name: "link_agenda", title: "Sala", default: "Não Informado" },
    description: { name: "codigo_verificador", title: "Responsável" },
    startTime: { name: "data_inicio", title: "Começa" },
    endTime: { name: "data_fim", title: "Termina" },
  };

  return (
    <>
      <SidebarFarmaceutico>
        <ScheduleComponent eventSettings={{ dataSource: data, fields: fields }}>
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Day, Week, WorkWeek, Month]} />
        </ScheduleComponent>
      </SidebarFarmaceutico>
    </>
  );
};

export default AgendaFarm;
