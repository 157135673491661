import Axios from "axios";
import React, { useEffect, useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";

function ListaPacientes() {
  Axios.defaults.withCredentials = true;

  const [lista, setLista] = useState([]);

  const pacientes = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mydigicare.com.br:3002/usuarios", {
      tipo: 2,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }

      setLista(response.data);
    });
  };

  useEffect(() => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mydigicare.com.br:3002/usuarios", {
      tipo: 2,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }

      setLista(response.data);
    });
  }, []);

  return (
    <SidebarAdministrativo>
      <table class="content-table">
        <thead>
          <tr>
            <th>NOME</th>
            <th>CPF</th>
            <th>EMAIL</th>
            <th>ESTADO</th>
            <th>CIDADE</th>
            <th>TELEFONE</th>
          </tr>
        </thead>
        <tbody align="justify">
          {lista.map((item) => (
            <tr>
              <th>{item.nome}</th>
              <th>{item.cpf}</th>
              <th>{item.email}</th>
              <th>{item.estado}</th>
              <th>{item.cidade}</th>
              <th>{item.telefone}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </SidebarAdministrativo>
  );
}

export default ListaPacientes;
