import React, { useState, useEffect } from "react";
import {
  Document,
  Text,
  Page,
  StyleSheet,
  View,
  Image,
  Font,
} from "@react-pdf/renderer";
// import logo
import logo from "./logo.png";

const styles = StyleSheet.create({
  page: {
    textAlign: "center",
    padding: 30,
  },
  text: {
    textAlign: "justify",
    marginTop: 30,
    fontSize: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 18,
  },
  textAssinatura: {
    textAlign: "justify",
    marginTop: 60,
    fontSize: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 18,
  },
  textNome: {
    marginTop: 50,
    fontSize: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 18,
  },
  textLine: {
    textAlign: "justify",
    fontSize: 12,
    paddingLeft: 18,
    paddingRight: 18,
  },
  textLine2: {
    fontSize: 12,
    paddingLeft: 18,
    paddingRight: 18,
  },
  textEvolucao: {
    textAlign: "justify",
    marginTop: 50,
    fontSize: 12,
    padding: 18,
  },
  textTitulo: {
    marginTop: 35,
    paddingRight: 60,
    fontSize: 16,
    fontWeight: "bold",
  },
  layout: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: 130,
    marginLeft: 19,
  },
  layout2: {
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
  },
});

const dataAtual = new Date();

// Obtendo o dia
const dia = dataAtual.getDate();

// Obtendo o mês (lembrando que o valor do mês começa em 0 para janeiro)
const mes = dataAtual.getMonth() + 1;

// Obtendo o ano com quatro dígitos
const ano = dataAtual.getFullYear();

// Obtendo a hora
const hora = dataAtual.getHours();

// Obtendo os minutos
const minutos = dataAtual.getMinutes();

function formatTime(hours, minutes) {
  // Converte horas e minutos para strings
  let hoursStr = hours.toString();
  let minutesStr = minutes.toString();

  // Garante que tanto as horas quanto os minutos tenham pelo menos 2 dígitos,
  // preenchendo com '0' se necessário
  hoursStr = hoursStr.padStart(2, '0');
  minutesStr = minutesStr.padStart(2, '0');

  // Combina as horas e os minutos formatados
  return hoursStr + ':' + minutesStr;
}

const PDFFile = ({ nome, cpf, responsavel, evolucao, crf, crf_uf}) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.layout}>
        <Image src={logo} style={styles.image} />
        <Text style={styles.textTitulo}>COMPROVANTE DE ATENDIMENTO</Text>
      </View>
      <Text style={styles.text}>Nome paciente: {nome}</Text>
      <Text style={styles.textLine}>CPF: {cpf}</Text>
      <Text style={styles.textLine}>
        Profissional responsável: {responsavel}
      </Text>
      <Text style={styles.textLine}>
        Data: {dia}/{mes}/{ano} - {formatTime(hora, minutos)}
      </Text>
      <Text style={styles.text}>Evolução: {evolucao}</Text>
      <Text style={styles.textAssinatura}>ASSINATURA ELETRÔNICA</Text>
      <View style={styles.layout2}>
        <Text style={styles.textNome}>{responsavel}</Text>
        <Text style={styles.textLine2}>CRF {crf_uf}-{crf}</Text>
      </View>
    </Page>
  </Document>
);

export default PDFFile;
