import React, { useState, useEffect } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import Axios from "axios";
import InputMask from "react-input-mask";
import { getCurrentDate, getCurrentFullDate } from "../../utils/date";
import { verifyDate } from "../../utils/verifyDate";
import { Redirect, useHistory } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
const MarcarTeleA = (props) => {
  const [paciente_cpfReg, setPaciente] = useState("");
  const [teleaID, setTeleaID] = useState("");
  const [listaTeleAss, setListaTeleAss] = useState([]);
  const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
  const [listaNutricao, setListaNutricao] = useState([]);
  const [listaPsicologia, setListaPsicologia] = useState([]);
  const [listaEducadorFisico, setListaEducadorFisico] = useState([]);
  const [educadorFisico, setEducadorFisico] = useState("");
  const [farmaceutico, setFarmaceutico] = useState("");
  const [nutricao, setNutricao] = useState("");
  const [psicologia, setPsicologia] = useState("");
  const [tipoRef, setTipo] = useState("Primeira consulta");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [statusReg, setStatusReg] = useState("Aberta");
  const [telefone, setTelefone] = useState("");
  const [pront, setPront] = useState("");
  const [nomePaciente, setNomePaciente] = useState("");
  const [linkReg, setLinkReg] = useState("");
  const [origem, setOrigem] = useState("");
  const [filial, setFilial] = useState("");
  let history = useHistory();
  Axios.defaults.withCredentials = true;
  const marcarAtendimento = (prontId, dataInicio) => {
    Axios.post(
      "https://atendimento.mydigicare.com.br:3002/adicionaAtendimento",
      {
        data: dataInicio,
        id: prontId,
      }
    ).then((response) => {
      if (response.data.message == "0") {
        alert("Erro, por favor tente novamente!");
      }
    });
  };
  const criaPront = () => {
    Axios.defaults.withCredentials = false;
    if (
      telefone == "" ||
      dataInicio == "" ||
      dataFim == "" ||
      nomePaciente == "" ||
      origem == "" ||
      filial == "" ||
      (farmaceutico == "" && nutricao == "" && psicologia == "") ||
      dataInicio == "" ||
      dataFim == ""
    ) {
      alert("Preencha todos os campos!");
    } else {
      if (dataFim < dataInicio) {
        alert("ERRO! Data inicial não pode ser maior que a data final!");
      } else {
        Axios.post(
          "https://atendimento.mydigicare.com.br:3002/criaProntuario",
          {
            telefone: telefone,
            nome: nomePaciente,
            origem: origem,
            filial: filial,
          }
        ).then((response) => {
          if (response.data.message != "0") {
            //alert(response.data.insertId)
            setPront(response.data.insertId);
            marcaTeleAss(response.data.insertId);
          } else {
            alert("Erro criar prontuário!");
          }
        });
      }
    }
  };
  const marcaTeleAss = (prontId) => {
    Axios.defaults.withCredentials = false;
    var url = "https://atendimento.mydigicare.com.br/room/" + prontId;
    var link_paciente =
      "https://atendimento.mydigicare.com.br/atendimento/" + prontId;
    var linkString =
      "<a href=" +
      '"https://atendimento.mydigicare.com.br/room/' +
      prontId +
      '" target="_blank">https://atendimento.mydigicare.com.br/room/' +
      prontId +
      "</a>";
    Axios.post("https://atendimento.mydigicare.com.br:3002/marcaTeleAss", {
      dataInicio: dataInicio,
      dataFim: dataFim,
      link: url,
      link_agenda: linkString,
      link_paciente: link_paciente,
      paciente: paciente_cpfReg,
      farmaceutico: farmaceutico,
      nutricao: nutricao,
      psicologia: psicologia,
      educadorFisico: educadorFisico,
      pront_id: prontId,
      status: statusReg,
      tipo: tipoRef,
    }).then((response) => {
      if (response.data.message == "1") {
        alert("Teleassistência marcada com sucesso!");
        marcarAtendimento(prontId, dataInicio);
        history.push("/adm");
      } else {
        alert("Erro ao criar teleassistência!");
      }
    });
  };
  useEffect(() => {
    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/lista_farmaceuticos"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaFarmaceutico(response.data);
    });

    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/lista_educador_fisico"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaEducadorFisico(response.data);
    });

    Axios.get("https://atendimento.mydigicare.com.br:3002/lista_nutricao").then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response.data[0]);
        setListaNutricao(response.data);
      }
    );

    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/lista_psicologia"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaPsicologia(response.data);
    });
    Axios.get(
      "https://atendimento.mydigicare.com.br:3002/retornaIDNomeTeleAssistencia"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  }, []);
  const remarcaTeleA = () => {
    if (verifyDate(dataInicio, dataFim)) {
      Axios.defaults.withCredentials = false;
      Axios.post("https://atendimento.mydigicare.com.br:3002/remarcaTeleA", {
        teleaID: teleaID,
        tipo: tipoRef,
        dataInicio: dataInicio,
        dataFim: dataFim,
      }).then((response) => {
        if (response.data.message == "1") {
          Axios.post(
            "https://atendimento.mydigicare.com.br:3002/retornaProntuarioID",
            {
              id: teleaID,
            }
          ).then((response) => {
            marcarAtendimento(response.data[0].id, dataInicio);
          });
          alert("Teleassistência remarcada com sucesso!");
          history.push("/adm");
        } else {
          alert("Erro ao remarcar teleassistência!");
        }
      });
    } else {
      alert("A data de início não pode ser menor que a de fim.");
    }
  };
  if (tipoRef == "Primeira consulta") {
    return (
      <>
        <SidebarAdministrativo>
          <div className="main__container">
            <div className="form">
              <div className="div2">
                <div className="item">
                  <p>Tipo de consulta: </p>
                  <select
                    value={tipoRef}
                    onChange={(e) => {
                      setTipo(e.target.value);
                    }}
                  >
                    <option>Acompanhamento</option>
                    <option>Encerramento</option>
                    <option>Primeira consulta</option>
                  </select>
                </div>
              </div>
              <div className="titulo">
                <h3>MARCAR TELEASSISTÊNCIA</h3>
              </div>
              <div className="div_teste">
                <div className="div2">
                  <div className="item">
                    <p>Nome paciente: </p>
                    <input
                      type="text"
                      onChange={(e) => {
                        setNomePaciente(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="item">
                    <p>Fone: </p>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={telefone}
                      onChange={(e) => {
                        setTelefone(e.target.value);
                      }}
                    ></InputMask>
                  </div>
                </div>
                <div className="div2"></div>
                <div className="div2">
                  <div className="item">
                    <p>Farmacêutico: </p>
                    <select
                      value={farmaceutico}
                      onChange={(e) => {
                        setFarmaceutico(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {listaFarmaceutico.map((option) => (
                        <option value={option.id}>{option.nome}</option>
                      ))}
                    </select>
                  </div>
                  <div className="item">
                    <p>Psicologo: </p>
                    <select
                      value={psicologia}
                      onChange={(e) => {
                        setPsicologia(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {listaPsicologia.map((option) => (
                        <option value={option.id}>{option.nome}</option>
                      ))}
                    </select>
                  </div>
                  <div className="item">
                    <p>Educador Físico: </p>
                    <select
                      value={educadorFisico}
                      onChange={(e) => {
                        setEducadorFisico(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {listaEducadorFisico.map((option) => (
                        <option value={option.id}>{option.nome}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="div2">
                  <div className="item">
                    <p>Nutrição: </p>
                    <select
                      value={nutricao}
                      onChange={(e) => {
                        setNutricao(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {listaNutricao.map((option) => (
                        <option value={option.id}>{option.nome}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="div2">
                  <div className="item">
                    <p>Filial: </p>
                    <select
                      value={filial}
                      onChange={(e) => {
                        setFilial(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value="1">RS</option>
                      <option value="2">BA</option>
                    </select>
                  </div>
                  <div className="item">
                    <p>Origem: </p>
                    <select
                      value={origem}
                      onChange={(e) => {
                        setOrigem(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option>PROTRAC</option>
                      <option>Privado</option>
                      <option>SUS</option>
                      <option>Plano de Saúde</option>
                      <option>Eldorado</option>
                      <option>Espumoso</option>
                      <option>Demedia</option>
                      <option>HPS</option>
                      <option>Outro</option>
                    </select>
                  </div>
                </div>
                <div className="div2">
                  <div className="item">
                    <p>Observações: </p>
                    <input type="text"></input>
                  </div>
                </div>
                <div className="div2">
                  <div class="item">
                    <p>Data Inicio: </p>
                    <input
                      type="datetime-local"
                      min={getCurrentFullDate()}
                      onChange={(e) => {
                        setDataInicio(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="item">
                    <p>Data Fim: </p>
                    <input
                      type="datetime-local"
                      min={getCurrentFullDate()}
                      onChange={(e) => {
                        setDataFim(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="div2">
                  <div className="item">
                    <p></p>
                    <button className="teste" onClick={criaPront}>
                      MARCAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SidebarAdministrativo>
      </>
    );
  } else {
    return (
      <>
        <SidebarAdministrativo>
          <div className="main__container">
            <div className="form">
              <div className="div2">
                <div className="item">
                  <p>Tipo de consulta: </p>
                  <select
                    value={tipoRef}
                    onChange={(e) => {
                      setTipo(e.target.value);
                    }}
                  >
                    <option>Acompanhamento</option>
                    <option>Encerramento</option>
                    <option>Primeira consulta</option>
                  </select>
                </div>
              </div>
              <div className="titulo">
                <h3>REMARCAR TELEASSISTÊNCIA</h3>
              </div>
              <div className="div_teste">
                <div className="div2">
                  <div className="item">
                    <p>ID da Assistência: </p>
                    <input
                      value={teleaID}
                      readOnly
                      type="text"
                      onChange={(e) => {
                        setTeleaID(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="item">
                    <p>Selecionar paciente: </p>
                    <Autocomplete
                      className="autocomplete-field"
                      freeSolo
                      getOptionLabel={(option) => option.nome}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setTeleaID(newValue.id);
                        }
                      }}
                      id="autocomplete-list-box"
                      options={listaTeleAss}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </div>
                </div>
                <div className="div2">
                  <div class="item">
                    <p>Data Inicio: </p>
                    <input
                      type="datetime-local"
                      min={getCurrentFullDate()}
                      onChange={(e) => {
                        setDataInicio(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="item">
                    <p>Data Fim: </p>
                    <input
                      type="datetime-local"
                      min={getCurrentFullDate()}
                      onChange={(e) => {
                        setDataFim(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="div2">
                  <div className="item">
                    <p></p>
                    <button className="teste" onClick={remarcaTeleA}>
                      REMARCAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SidebarAdministrativo>
      </>
    );
  }
};
export default MarcarTeleA;
