import React, {useState, useEffect} from "react";
import Table from "@mui/material/Table"
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Axios from "axios";
import './styles.css'


export default function TabelaAdesaoMedicamentos(){

    const [pacientesBaixaAdesao, setPacientesBaixaAdesao] = useState([]);

    useEffect(() => {
        Axios.get("http://localhost:3002/pacientes_baixa_adesao_medicamento"
        ).then((response) => {
          console.log(response.data)
          setPacientesBaixaAdesao(response.data)
        })
    }, [])

    return(
        <>
            <h1>Adesão de Medicamentos <i class="fa fa-medkit" aria-hidden="true"></i></h1>
        <p>Pacientes com baixa adesão aos medicamentos são listados aqui</p>
        <div className="adesaoMedicamentosGroup">
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="HeadAdesaoMedicamentos" sx={{backgroundColor: '#EAEAEA' }}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">Medicamento</TableCell>
            <TableCell align="left">Celular</TableCell>
            <TableCell align="left">Status Telassistência</TableCell>
            <TableCell align="left">Adesão</TableCell>
            <TableCell align="left">Link Atendimento</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
            {pacientesBaixaAdesao.map((item) => (
            <TableRow
              key={item.prontuario_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <b>{item.prontuario_id}</b>
              </TableCell>
              <TableCell align="left">{item.nomePaciente}</TableCell>
              <TableCell align="left">{item.medicamento}</TableCell>
              <TableCell align="left">{item.fone}</TableCell>
              <TableCell align="left">{item.statusTeleAssistencia}</TableCell>
              <TableCell align="left"><b>{Math.round(item.adesao)} %</b></TableCell>
              <TableCell align="left" className="prontuario-url"><b><a href={`/room/${item.prontuario_id}`}>Prontuário</a></b></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
        </>
    )
}